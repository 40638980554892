<template>
  <div>

    <base-template
        title="CustomerReceivable"
        itemView="list"
        :text-button-add="$t('addPayment')"
        :is-button-add-disabled="!selectedItems.length"
        :columns="columns"
        :filters="filters"
        :items="outgoingInvoices2"
        :context="outgoingInvoicesContext"
        :isActionColumnEnabled="true"
        :openViewWindow="openViewWindow"
        selectable="multi"

        @fetch="fetchOutgoingInvoices2"
        @rowSelected="rowSelected"
        @addItem="addPayment(selectedItems)"
    >
      <!--  =========================== -->
      <!--  ===      Grid view      === -->
      <!--  =========================== -->
      <template v-slot:gridView="{item}">
        <outgoing-invoice-card
            :outgoing-invoice="item"
            :is-downloadable="false"
            :is-viewable="true"

            @click="showDetails(item)"
            @view="showDetails(item)"
        />
      </template>

      <!--  =========================== -->
      <!--  ===      List view      === -->
      <!--  =========================== -->
      <!--      Actions-->
      <template #listView_cell_actions="{item}">
        <div class="text-nowrap">

          <!--          View-->
          <button-view
              @click.native.stop="showDetails(item)"
              v-b-tooltip.hover.left="$t('View')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Payment-->
          <button-payment
              @click.native.stop="addPayment([item])"
              v-b-tooltip.hover.left="capitalize($t('addPayment'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

        </div>
      </template>


      <!--  =========================== -->
      <!--  ===     Select view     === -->
      <!--  =========================== -->
      <template #leftViewWindow="{item}">
        <outgoing-invoice-card
            :outgoing-invoice="item"
            :is-downloadable="false"
            :is-viewable="true"
            :is-selected="item.id==selectedItems[0].id"

            @click="showDetails(item)"
            @view="showDetails(item)"
        />
      </template>
      <template #windowView
                v-if="selectedItems.length">
        <div class="d-flex justify-content-between mb-1">
          <h4>{{ selectedItems[0]._display }}</h4>
          <icon icon="times"
                class="cursor-pointer"
                @click.native="showDetails(null)"/>
        </div>

        <b-alert
            show
            :variant="outgoingInvoicePaymentBannerColor(selectedItems[0])"
        >
          <div class="alert-body d-flex justify-content-between">
            <icon icon="cash-register"
                  class="top-0 my-auto"/>
            <span class="ml-50 w-100 my-auto">
              <span v-if="outgoingInvoicePaymentBannerColor(selectedItems[0]) == 'danger'">
                {{ $t('The payment of this customer invoice is late') }}
              </span>
              <span v-else-if="outgoingInvoicePaymentBannerColor(selectedItems[0]) == 'warning'">
                {{ $t('A partial payment has been recorded for this customer invoice') }}
              </span>
              <span v-else>
                {{ $t('This customer invoice is in Open status') }}
              </span>
            </span>
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                size="sm"
                class="text-nowrap"
                :variant="'outline-'+outgoingInvoicePaymentBannerColor(selectedItems[0])"
                @click="addPayment([selectedItems[0]])"
            >
              {{ $t('addPayment') }}
            </b-button>
          </div>
        </b-alert>

        <view-outgoing-invoice
            class="invoice-preview"
            :invoice="selectedItems[0]"
        />


      </template>
    </base-template>

    <modal-payment
        :payment.sync="currentPayment"
        :title="'id' in currentPayment?$t('EditPayment'):$t('NewPayment')"
        :isOpen.sync="paymentModalShow"
        :formMaxAmount="maxPaymentAmount"

        @submitValidated="submitValidatedPaymentLocal"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../utils/filter'
import { usePayments } from '../../accounting/usePayment'

import BaseTemplate from '../../../components/base/Base'
import ButtonView from '../../../components/button/View'
import ButtonPayment from '../../../components/button/Payment'
import OutgoingInvoiceCard from '../../../components/card/OutgoingInvoice2'
import ModalPayment from '../../../components/prompt/Payment2'
import ViewOutgoingInvoice from '../../../components/views/Invoice'
import i18n from '../../../libs/i18n'
import useAPI from '../../../utils/useAPI'
import Ripple from 'vue-ripple-directive'
import store from '../../../store'
import moment from 'moment'

export default {
  directives: {
    Ripple,
  },
  components: {
    BaseTemplate,
    ButtonView,
    ButtonPayment,
    OutgoingInvoiceCard,
    ModalPayment,
    ViewOutgoingInvoice
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const columns = ref([
      {
        display: capitalize(i18n.t('billingDate')),
        key: 'billingDate',
        sortable: true,
        cell: {
          isDate: true
        }
      },
      {
        display: capitalize(i18n.t('billNumber')),
        key: 'billNumber',
        sortable: true
      },
      {
        display: capitalize(i18n.t('status')),
        key: 'status',
        sortable: false,
        cell: {
          translate: true,
          useBadge: true,
          type: 'paymentStatus',
          // center: true
        }
      },
      {
        display: capitalize(i18n.t('contact')),
        key: 'contact',
        sortable: false,
        cell: {
          isContact: true
        }
      },
      {
        display: capitalize(i18n.t('totalAmount')),
        key: 'totalAmount',
        sortable: true,
        cell: {
          isCurrency: true
        }
      },
      {
        display: capitalize(i18n.t('balance')),
        key: '_balance',
        sortable: false,
        cell: {
          isCurrency: true
        }
      },

    ])
    const filters = ref([
      {
        text: capitalize(i18n.tc('CustomerReceivable')),
        key: '_filter_isCustomerReceivable',
        searchCriteria: [{
          column_name: 'bankOperations',
          comparator: 'notTotallyPaid',
        }],
        selected: true,
        disabled: true
      },
      {
        text: capitalize(i18n.tc('currentFiscalYear')),
        key: '_filter_isCurrentFiscalYear',
        searchCriteria: [{
          column_name: 'billingDate',
          comparator: 'range',
          search_text_0: '2022-07-01',
          search_text_1: '2023-06-30'
        }],
        // selected: true,
      },
      {
        text: capitalize(i18n.tc('company')),
        key: '_filter_isCustomerCompany',
        searchCriteria: [{
          column_name: 'customerCompany',
          comparator: 'notNull',
        }],
      },
      {
        text: capitalize(i18n.tc('individual')),
        key: '_filter_isCustomerIndividual',
        searchCriteria: [{
          column_name: 'customerIndividual',
          comparator: 'notNull',
        }],
      },
      {
        text: capitalize(i18n.tc('myOutgoingInvoices')),
        key: '_filter_myOutgoingInvoices',
        searchCriteria: [{
          column_name: 'createdBy',
          comparator: 'me',
        }],
      },
    ])
    const openViewWindow = ref(false)
    const selectedItems = ref([])
    const currentPayment = ref({})
    const paymentModalShow = ref(false)
    const maxPaymentAmount = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { outgoingInvoices2, outgoingInvoicesContext, autocompleteBankAccounts } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchOutgoingInvoices2,
      fetchAutocompletePaymentMethods,
      fetchAutocompleteBankAccounts,
    } = useAPI()

    const { submitValidatedPayment } = usePayments()

    const hasInitialFilter = () => {
      return filters.value.some(f => f.selected)
    }

    const rowSelected = (rowSelected) => {
      selectedItems.value = rowSelected
    }

    const showDetails = (outgoingInvoice) => {
      if (outgoingInvoice != null) {
        store.dispatch('outgoingInvoice2/getOutgoingInvoice', outgoingInvoice.id)
            .then(response => {
              selectedItems.value = [outgoingInvoice]
              selectedItems.value[0] = response
              openViewWindow.value = true
            })

      } else {
        selectedItems.value = []
        openViewWindow.value = false
      }
    }

    const addPayment = (outgoingInvoices) => {
      if (outgoingInvoices.length) {
        let amount = 0
        let paymentRelations = []
        outgoingInvoices.forEach(oi => {
          amount -= parseFloat(oi._balance)
          paymentRelations.push({
            amount: -parseFloat(oi._balance),
            outgoingInvoice: oi
          })
        })

        currentPayment.value = {
          paymentMethod: { id: 1, name: i18n.t('Bank transfert') },
          date: (new Date()).toISOString().slice(0, 10),
          paymentRelations: paymentRelations,
          bankAccount: autocompleteBankAccounts.value.find(ba=>ba.id==1)
        }

        maxPaymentAmount.value = JSON.parse(JSON.stringify(amount))

        paymentModalShow.value = true
      }
    }

    const submitValidatedPaymentLocal = () => {
      submitValidatedPayment(currentPayment.value)
          .then(response => {
            currentPayment.value = {}
            selectedItems.value = []
            maxPaymentAmount.value = null
            openViewWindow.value = false
            paymentModalShow.value = false
          })
    }

    const outgoingInvoicePaymentBannerColor = (outgoingInvoice) => {
      if (outgoingInvoice._paymentStatus == 'Waiting for payment') {
        return 'secondary'
      } else if (outgoingInvoice._paymentStatus == 'Partial payment') {
        if (moment() > moment(outgoingInvoice._dueDate)) {
          return 'danger'
        } else {
          return 'warning'
        }
      } else {
        return 'danger'
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    if (!hasInitialFilter()) {
      fetchOutgoingInvoices2()
    }
    fetchAutocompletePaymentMethods()
    fetchAutocompleteBankAccounts()

    return {
      // Components
      capitalize,

      // Data
      columns,
      filters,
      openViewWindow,
      selectedItems,
      currentPayment,
      paymentModalShow,
      maxPaymentAmount,

      // Computed
      outgoingInvoices2,
      outgoingInvoicesContext,

      // Methods
      fetchOutgoingInvoices2,
      rowSelected,
      showDetails,
      addPayment,
      submitValidatedPaymentLocal,
      outgoingInvoicePaymentBannerColor,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>